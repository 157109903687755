import { combineReducers } from 'redux';

import messages, * as fromMessages from './messages';
import status, * as fromStatus from './status';

export default combineReducers({
  messages,
  status,
});

const selectMessageCenter = state => state.messageCenter;

export const selectStatus = state => selectMessageCenter(state).status;
export const selectMessages = state => selectMessageCenter(state).messages;

export const needsInitializing = state => fromStatus.needsInitializing(selectStatus(state));
export const allPromotions = state => fromMessages.selectPromotions(selectMessages(state));
export const selectZoneZeroPromotions = state =>
  fromMessages.selectPromotions(selectMessages(state)).filter(promotion => promotion.zone === 0);
export const selectPromotionsNotZoneZero = state =>
  fromMessages.selectPromotions(selectMessages(state)).filter(promotion => promotion.zone !== 0);
export const selectPromotion = state => fromMessages.selectPromotions(selectMessages(state))[0];
export const selectUserMessagesByPlacement = (state, placement) =>
  fromMessages.selectUserMessagesByPlacement(selectMessages(state), placement);
export const selectMessageByPlacementAndPriority = (state, placement) =>
  fromMessages.selectMessageByPlacementAndPriority(selectMessages(state), placement);

export const selectDashboardMessages = state =>
  fromMessages.selectDashboardMessages(selectMessages(state));

export const selectDrawerMessages = state =>
  fromMessages.selectDrawerMessages(selectMessages(state));
export const selectUnreadDrawerMessages = state =>
  fromMessages.selectUnreadDrawerMessages(selectMessages(state));
export const selectOrderedDrawerMessages = state =>
  fromMessages.selectOrderedDrawerMessages(selectMessages(state));

export const selectRewardMessage = state => fromMessages.selectRewardMessage(selectMessages(state));
export const pauseMessage = state => fromMessages.pauseMessage(selectMessages(state));
export const creditMessage = state => fromMessages.selectCreditMessage(selectMessages(state));
