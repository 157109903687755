import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import boxes, * as fromBoxes from './boxes';
import boxWeeklyBaskets, * as fromBoxWeeklyBaskets from './boxWeeklyBaskets';
import gifterBoxes from './gifterBoxes';
import gifterBoxesStatus, * as fromGifterBoxesStatus from './gifterBoxesStatus';
import menuCategories, * as fromMenuCategories from './menuCategories';
import menuMeals, * as fromMenuMeals from './menuMeals';
import menus, * as fromMenus from './menus';
import menuSlugs from './menuSlugs';
import menuStatus, * as fromMenuStatus from './menuStatus';
import recipientInfo from './recipientInfo';
import reviews, * as fromNsoReviews from './reviews';
import status, * as fromStatus from './status';

export default combineReducers({
  menuSlugs,
  menus,
  menuMeals,
  menuCategories,
  boxes,
  recipientInfo,
  menuStatus,
  status,
  gifterBoxes,
  gifterBoxesStatus,
  boxWeeklyBaskets,
  reviews,
});

const selectSendABox = state => state.sendABox;

export const selectMenuSlugs = state => selectSendABox(state).menuSlugs;
export const selectMenus = state => selectSendABox(state).menus;
export const selectMenuMeals = state => selectSendABox(state).menuMeals;
export const selectMenuCategories = state => selectSendABox(state).menuCategories;
export const selectMenuStatus = state => selectSendABox(state).menuStatus;
export const selectStatus = state => selectSendABox(state).status;
export const selectBoxWeeklyBaskets = state => selectSendABox(state).boxWeeklyBaskets;
export const selectNsoReviews = state => selectSendABox(state).reviews;

export const selectBoxes = state => selectSendABox(state).boxes;

export const selectMealsForBox = (state, boxId) =>
  fromBoxes.selectMealsForBox(selectBoxes(state), boxId);

export const selectMenuCategoriesLoading = (state, slug, mealPlan) =>
  fromMenuCategories.selectMenuCategoriesLoading(selectMenuCategories(state), slug, mealPlan);
export const selectMenuCategoriesData = (state, slug, mealPlan) =>
  fromMenuCategories.selectMenuCategoriesData(selectMenuCategories(state), slug, mealPlan);

export const selectMealsForMenu = (state, menuSlug, mealPlan) =>
  fromMenuMeals.selectMealsForMenu(selectMenuMeals(state), menuSlug, mealPlan);

export const selectMenu = (state, menuSlug) => fromMenus.selectMenu(selectMenus(state), menuSlug);

export const selectRecipientInfo = state => selectSendABox(state).recipientInfo;

export const selectInitialMenuSlugs = createSelector(selectMenuSlugs, menuSlugs =>
  menuSlugs.slice(0, 1)
);

export const menuNeedsInitialization = (state, menuSlug, mealPlan) =>
  fromMenuStatus.needsInitialization(selectMenuStatus(state), menuSlug, mealPlan);

export const needsInitializing = state => fromStatus.needsInitializing(selectStatus(state));

// Gifter Boxes
export const selectGifterBoxes = state => selectSendABox(state).gifterBoxes;
export const selectBoxesStatus = state => selectSendABox(state).gifterBoxesStatus;
export const boxNeedsInitialization = state =>
  fromGifterBoxesStatus.needsInitialization(selectBoxesStatus(state));
export const areGifterBoxesLoading = state =>
  fromGifterBoxesStatus.areBoxesLoading(selectBoxesStatus(state));

export const selectBoxWeeklyBasket = (state, boxHashId) =>
  fromBoxWeeklyBaskets.selectBoxBasket(selectBoxWeeklyBaskets(state), boxHashId);

export const selectReview = (state, mealId) =>
  fromNsoReviews.selectReview(selectNsoReviews(state), mealId);
export const selectComments = state => fromNsoReviews.selectComments(selectNsoReviews(state));
