import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_MEAL_SEARCH = 'GET_MEAL_SEARCH';
export const GET_MEAL_SEARCH_REQUEST = 'GET_MEAL_SEARCH_REQUEST';
export const GET_MEAL_SEARCH_RESPONSE = 'GET_MEAL_SEARCH_RESPONSE';
export const GET_MEAL_SEARCH_ERROR = 'GET_MEAL_SEARCH_ERROR';

export const getMeals = (searchTerm, planId, limit = 12, offset = 0, page = 1) =>
  makeApiRequest({
    method: RequestService.getMenuSearchMeals,
    requestType: GET_MEAL_SEARCH,
    params: { searchTerm, planId, limit, offset, page },
  });
