import {
  SET_DEFAULT_ADDRESS_RESPONSE,
  DESTROY_ADDRESS_RESPONSE,
  UPDATE_ADDRESS_RESPONSE,
  CREATE_ADDRESS_RESPONSE,
} from 'actions/addresses';
import {
  ADD_BASKET_RESPONSE,
  UPDATE_BASKET_RESPONSE,
  UNDONATE_BASKET_RESPONSE,
  UNDONATE_BASKET_ERROR,
  DONATE_BASKET_RESPONSE,
  DONATE_BASKET_ERROR,
  SKIP_BASKET_RESPONSE,
  SKIP_BASKET_ERROR,
  UNSKIP_BASKET_RESPONSE,
  UNSKIP_BASKET_ERROR,
  GET_BASKET_ERROR,
} from 'actions/baskets';
import {
  SET_SUCCESS_MESSAGE,
  setSuccessMessage,
  SET_ERROR_MESSAGE,
  setErrorMessage,
  clearMessage,
} from 'actions/messages';
import { SHARE_RECIPE_RESPONSE } from 'actions/shareRecipe';
import { GET_SHOPPING_LIST_MEAL_ERROR } from 'actions/shoppingList';
import { UPDATE_TASTE_PROFILE_RESPONSE } from 'actions/tasteProfile';
import {
  ADD_MEAL_ADDON_SELECTION_REQUEST,
  SEND_INVITATION_RESPONSE,
  UPDATE_USER_RESPONSE,
  UPDATE_PASSWORD_RESPONSE,
  UPDATE_PAYMENT_RESPONSE,
  UPDATE_SUBSCRIPTION_RESPONSE,
  UNPAUSE_USER_ERROR,
  PAUSE_USER_ERROR,
  UNPAUSE_USER_RESPONSE,
  UPDATE_OPT_IN_SELECTIONS_RESPONSE,
  UPDATE_EMAIL_SETTINGS_RESPONSE,
} from 'actions/user';

let timeout;

const middleware =
  ({ dispatch }) =>
  next =>
  action => {
    const { type } = action;
    switch (type) {
      case ADD_BASKET_RESPONSE:
      case UPDATE_BASKET_RESPONSE:
        dispatch(setSuccessMessage('Your order has been updated.'));
        break;
      case DONATE_BASKET_RESPONSE:
        dispatch(setSuccessMessage('Your order has been donated.'));
        break;
      case SKIP_BASKET_RESPONSE:
        dispatch(setSuccessMessage('Your order has been skipped.'));
        break;
      case UNDONATE_BASKET_RESPONSE:
        dispatch(setSuccessMessage('Your donation has been cancelled.'));
        break;
      case UNSKIP_BASKET_RESPONSE:
        dispatch(setSuccessMessage('Your order has been unskipped.'));
        break;
      case GET_BASKET_ERROR:
        dispatch(
          setErrorMessage(
            'There was a problem fetching the menu. Please refresh the page and try again.'
          )
        );
        break;
      case SKIP_BASKET_ERROR:
        if (action.response.status === 404) {
          dispatch(setErrorMessage('That basket was already skipped.'));
        }
        break;
      case DONATE_BASKET_ERROR:
        dispatch(setErrorMessage('Unexpected error donating basket.'));
        break;
      case UNDONATE_BASKET_ERROR:
        dispatch(setErrorMessage('Unexpected error cancelling donation.'));
        break;
      case UNSKIP_BASKET_ERROR:
        if (action.response.status === 404) {
          dispatch(setErrorMessage('That basket was already unskipped.'));
        }
        break;
      case ADD_MEAL_ADDON_SELECTION_REQUEST:
        dispatch(setSuccessMessage('Your weekly add-ons have been updated.'));
        break;
      case UPDATE_OPT_IN_SELECTIONS_RESPONSE:
        dispatch(setSuccessMessage('Your premium options have been updated.'));
        break;
      case UPDATE_TASTE_PROFILE_RESPONSE:
        dispatch(setSuccessMessage('Profile updated! Check out your new recommendations.'));
        break;
      case UPDATE_SUBSCRIPTION_RESPONSE:
        dispatch(setSuccessMessage('Your subscription settings have been updated.'));
        break;
      case UPDATE_USER_RESPONSE:
        dispatch(setSuccessMessage('Your contact information has been updated.'));
        break;
      case UPDATE_ADDRESS_RESPONSE:
      case SET_DEFAULT_ADDRESS_RESPONSE:
        dispatch(setSuccessMessage('Your delivery address has been updated.'));
        break;
      case DESTROY_ADDRESS_RESPONSE:
      case CREATE_ADDRESS_RESPONSE:
        dispatch(setSuccessMessage('Your alternate addresses have been updated.'));
        break;
      case UPDATE_PASSWORD_RESPONSE:
        dispatch(setSuccessMessage('Your password has been updated.'));
        break;
      case UPDATE_EMAIL_SETTINGS_RESPONSE:
        dispatch(setSuccessMessage('Your email preferences have been updated.'));
        break;
      case UPDATE_PAYMENT_RESPONSE:
        dispatch(setSuccessMessage('Your payment has been updated.'));
        break;
      case UNPAUSE_USER_ERROR:
        if (action.response.status === 404) {
          // eslint-disable-next-line quotes
          dispatch(setErrorMessage("You've already reactivated your account."));
        } else if (action.response.error) {
          dispatch(setErrorMessage(action.response.error));
        }
        break;
      case PAUSE_USER_ERROR:
        if (action.response.status === 422) {
          // eslint-disable-next-line quotes
          dispatch(setErrorMessage("You're already paused."));
        }
        break;
      case UNPAUSE_USER_RESPONSE:
        dispatch(setSuccessMessage('Your account has been reactivated'));
        break;
      case SEND_INVITATION_RESPONSE:
        if (action.response.status === 200) {
          let message = 'Your invitation has been sent!';
          if (action.params.emails.length === 1) {
            message = `An email has been sent to ${action.params.emails[0]}`;
          }
          dispatch(setSuccessMessage(message));
        }
        break;
      case GET_SHOPPING_LIST_MEAL_ERROR:
        dispatch(setErrorMessage('Unexpected error adding meal to shopping list.'));
        break;
      case SHARE_RECIPE_RESPONSE:
        dispatch(setSuccessMessage('Recipe Sent'));
        break;
    }
    if (action.type === SET_SUCCESS_MESSAGE || action.type === SET_ERROR_MESSAGE) {
      clearTimeout(timeout);
      timeout = setTimeout(() => dispatch(clearMessage()), 5000);
    }

    return next(action);
  };

export default middleware;
