import {
  SET_NSO_BOX_RECIPIENT_INFO_RESPONSE,
  GET_NSO_BOX_RECIPIENT_INFO_RESPONSE,
  GET_NSO_BOX_RECIPIENT_INFO_REQUEST,
  CREATE_NSO_BOX_RECIPIENT_INFO_RESPONSE,
} from 'actions/sendABox';

const initialState = {
  id: null,
  name: '',
  email: '',
  phone: '',
  address: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_BOX_RECIPIENT_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NSO_BOX_RECIPIENT_INFO_RESPONSE:
      return {
        ...state,
        ...action.response.recipient,
        isLoading: false,
      };
    case SET_NSO_BOX_RECIPIENT_INFO_RESPONSE:
      return {
        ...state,
        ...action.response.recipient,
      };
    case CREATE_NSO_BOX_RECIPIENT_INFO_RESPONSE:
      return {
        ...state,
        ...action.response.recipient,
        address: {
          ...state.address,
          ...action.response.recipient.address,
        },
      };
    default:
      return state;
  }
};
