import { GET_NSO_UPCOMING_MENUS_RESPONSE } from 'actions/sendABox/menus';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_UPCOMING_MENUS_RESPONSE:
      return action.response.sendABox.map(box => box.menu.slug);
    default:
      return state;
  }
};
