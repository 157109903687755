import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const SHARE_RECIPE = 'SHARE_RECIPE';
export const SHARE_RECIPE_REQUEST = 'SHARE_RECIPE_REQUEST';
export const SHARE_RECIPE_RESPONSE = 'SHARE_RECIPE_RESPONSE';
export const SHARE_RECIPE_ERROR = 'SHARE_RECIPE_ERROR';

export const shareRecipe = params =>
  makeApiRequest({
    requestType: SHARE_RECIPE,
    method: RequestService.shareRecipe,
    params: params,
  });
