import {
  GET_MEAL_SEARCH_RESPONSE,
  GET_MEAL_SEARCH_REQUEST,
  GET_MEAL_SEARCH_ERROR,
} from 'actions/menuSearch';

const defaultState = {
  orderableMeals: [],
  pastMeals: [],
  numberOfPages: 0,
  loading: false,
  needsInitialization: true,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_MEAL_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        needsInitialization: true,
      };
    case GET_MEAL_SEARCH_RESPONSE:
      return {
        ...state,
        loading: false,
        needsInitialization: false,
        orderableMeals: action.response.orderableMeals,
        pastMeals: action.response.pastMeals,
        numberOfPages: action.response.responseMetadata.paging.pages,
      };
    case GET_MEAL_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        needsInitialization: false,
        orderableMeals: [],
        pastMeals: [],
        numberOfPages: 0,
      };
    default:
      return state;
  }
};
