import { SET_SUCCESS_MESSAGE, CLEAR_MESSAGE, SET_ERROR_MESSAGE } from 'actions/messages';

const defaultState = { content: null, visible: false, type: 'neutral' };

export default (state = defaultState, action) => {
  const { type, message } = action;
  switch (type) {
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        content: message,
        type: 'success',
        visible: true,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        content: message,
        type: 'error',
        visible: true,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        visible: false,
      };
    default:
      return state;
  }
};

export const selectToastMessage = state => state.messages;
