import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_BETA_BASKETS = 'GET_BETA_BASKETS';
export const GET_BETA_BASKETS_REQUEST = 'GET_BETA_BASKETS_REQUEST';
export const GET_BETA_BASKETS_RESPONSE = 'GET_BETA_BASKETS_RESPONSE';
export const GET_BETA_BASKETS_ERROR = 'GET_BETA_BASKETS_ERROR';

export const GET_BETA_BASKET = 'GET_BETA_BASKET';
export const GET_BETA_BASKET_REQUEST = 'GET_BETA_BASKET_REQUEST';
export const GET_BETA_BASKET_RESPONSE = 'GET_BETA_BASKET_RESPONSE';
export const GET_BETA_BASKET_ERROR = 'GET_BETA_BASKET_ERROR';

export const OPT_IN_BETA_BOX = 'OPT_IN_BETA_BOX';
export const OPT_IN_BETA_BOX_REQUEST = 'OPT_IN_BETA_BOX_REQUEST';
export const OPT_IN_BETA_BOX_RESPONSE = 'OPT_IN_BETA_BOX_RESPONSE';
export const OPT_IN_BETA_BOX_ERROR = 'OPT_IN_BETA_BOX_ERROR';

export const OPT_OUT_BETA_BOX = 'OPT_OUT_BETA_BOX';
export const OPT_OUT_BETA_BOX_REQUEST = 'OPT_OUT_BETA_BOX_REQUEST';
export const OPT_OUT_BETA_BOX_RESPONSE = 'OPT_OUT_BETA_BOX_RESPONSE';
export const OPT_OUT_BETA_BOX_ERROR = 'OPT_OUT_BETA_BOX_ERROR';

export const getBetaBaskets = () =>
  makeApiRequest({
    method: RequestService.getBetaBaskets,
    requestType: GET_BETA_BASKETS,
  });

export const getBetaBasket = menuId =>
  makeApiRequest({
    method: RequestService.getBetaBasket,
    requestType: GET_BETA_BASKET,
    params: { menuId },
  });

export const optIn = menuId =>
  makeApiRequest({
    method: RequestService.optInBetaBox,
    requestType: OPT_IN_BETA_BOX,
    params: { menuId },
  });

export const optOut = menuId =>
  makeApiRequest({
    method: RequestService.optOutBetaBox,
    requestType: OPT_OUT_BETA_BOX,
    params: { menuId },
  });
