import { GET_REACTIVATION_RESPONSE } from 'actions/restartCampaigns';

const defaultState = {};

export default (state = defaultState, action) => {
  const { type, response } = action;

  switch (type) {
    case GET_REACTIVATION_RESPONSE:
      return { ...state, ...response.restartCampaign };
    default:
      return state;
  }
};

export const selectRestartCampaign = state => state.restartCampaign;
