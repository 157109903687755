import get from 'lodash/get';

import {
  CREATE_NSO_BOX_RESPONSE,
  UPDATE_BOX_RESPONSE,
  GET_NSO_BOX_RESPONSE,
} from 'actions/sendABox';

const initialState = {
  boxId: null,
};

const boxSuccess = (state, boxHashId, boxResponse) => {
  const { box, recipient } = boxResponse;

  const updatedBox = {
    ...box,
    recipient: recipient,
  };

  return {
    ...state,
    [boxHashId]: updatedBox,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NSO_BOX_RESPONSE:
      return {
        ...state,
        boxId: action.response.box.id,
      };
    case UPDATE_BOX_RESPONSE:
      return boxSuccess(state, action.params.boxId, action.response);
    case GET_NSO_BOX_RESPONSE:
      return boxSuccess(state, action.params.boxId, action.response);
    default:
      return state;
  }
};

export const selectBox = (state, boxId) => get(state, boxId, { meals: [] });
export const selectMealsForBox = (state, boxId) => get(selectBox(state, boxId), 'meals') || [];
