import { GET_USER_RESPONSE } from 'actions/user';
import * as ErrorLogging from 'services/ErrorLogging';

function initializeUser(userResponseAction) {
  const { user } = userResponseAction.response;
  try {
    ErrorLogging.setUser({
      id: user.id,
      email: user.email,
      name: user.name,
    });
  } catch (error) {
    ErrorLogging.captureException(error, { action: userResponseAction });
  }
}

const middleware = () => next => action => {
  if (action.type === GET_USER_RESPONSE) initializeUser(action);

  try {
    return next(action);
  } catch (error) {
    ErrorLogging.captureException(error, { action: JSON.stringify(action) });
    throw error;
  }
};

export default middleware;
