import {
  GET_NUTRITIONAL_FACTS_REQUEST,
  GET_NUTRITIONAL_FACTS_RESPONSE,
  GET_NUTRITIONAL_FACTS_ERROR,
} from 'actions/nutritionalFacts';

const defaultState = {};

export default (state = defaultState, action) => {
  const { type, response } = action;

  switch (type) {
    case GET_NUTRITIONAL_FACTS_REQUEST:
    case GET_NUTRITIONAL_FACTS_ERROR:
      return {
        ...state,
      };
    case GET_NUTRITIONAL_FACTS_RESPONSE:
      return {
        ...state,
        [response.meal.id]: response.meal,
      };
    default:
      return state;
  }
};

export const selectMealsNutritionalFacts = state => state.nutritionalFacts;
