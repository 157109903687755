import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_NSO_MENU = 'GET_NSO_MENU';
export const GET_NSO_MENU_REQUEST = 'GET_NSO_MENU_REQUEST';
export const GET_NSO_MENU_RESPONSE = 'GET_NSO_MENU_RESPONSE';
export const GET_NSO_MENU_ERROR = 'GET_NSO_MENU_ERROR';

export const GET_NSO_UPCOMING_MENUS = 'GET_NSO_UPCOMING_MENUS';
export const GET_NSO_UPCOMING_MENUS_REQUEST = 'GET_NSO_UPCOMING_MENUS_REQUEST';
export const GET_NSO_UPCOMING_MENUS_RESPONSE = 'GET_NSO_UPCOMING_MENUS_RESPONSE';
export const GET_NSO_UPCOMING_MENUS_ERRORS = 'GET_NSO_UPCOMING_MENUS_ERRORS';

export const getNsoMenu = (menuSlug, mealPlan) =>
  makeApiRequest({
    requestType: GET_NSO_MENU,
    method: RequestService.getNsoMenu,
    params: { menuSlug, mealPlan },
  });

export const getNsoUpcomingMenus = (params = {}) =>
  makeApiRequest({
    requestType: GET_NSO_UPCOMING_MENUS,
    method: RequestService.getNsoUpcomingMenus,
    params,
  });
