import get from 'lodash/get';

import {
  GET_NSO_MENU_REQUEST,
  GET_NSO_MENU_RESPONSE,
  GET_NSO_MENU_ERROR,
} from 'actions/sendABox/menus';

const initialState = {};
const uninitializedStatus = Object.freeze({
  needsInitialization: true,
  isLoading: false,
  isError: false,
});

const menuRequest = (state, menuSlug, mealPlan) => ({
  ...state,
  [menuSlug]: {
    ...state[menuSlug],
    [mealPlan]: {
      ...state[menuSlug]?.[mealPlan],
      needsInitialization: false,
      isLoading: true,
    },
  },
});

const menuSuccess = (state, menuSlug, mealPlan) => ({
  ...state,
  [menuSlug]: {
    ...state[menuSlug],
    [mealPlan]: {
      ...state[menuSlug]?.[mealPlan],
      needsInitialization: false,
      isLoading: false,
      isError: false,
    },
  },
});

const menuError = (state, menuSlug, mealPlan) => ({
  ...state,
  [menuSlug]: {
    ...state[menuSlug],
    [mealPlan]: {
      ...state[menuSlug]?.[mealPlan],
      needsInitialization: false,
      isLoading: false,
      isError: true,
    },
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_MENU_REQUEST:
      return menuRequest(state, action.params.menuSlug, action.params.mealPlan);
    case GET_NSO_MENU_RESPONSE:
      return menuSuccess(state, action.params.menuSlug, action.params.mealPlan);
    case GET_NSO_MENU_ERROR:
      return menuError(state, action.params.menuSlug, action.params.mealPlan);
    default:
      return state;
  }
};

const selectMenuStatus = (state, menuSlug, mealPlan) =>
  get(state, [menuSlug, mealPlan], uninitializedStatus);

export const needsInitialization = (state, menuSlug, mealPlan) =>
  get(selectMenuStatus(state, menuSlug, mealPlan), 'needsInitialization', true);
export const isMenuError = (state, menuSlug, mealPlan) =>
  get(selectMenuStatus(state, menuSlug, mealPlan), 'isError', false);
