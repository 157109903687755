import omit from 'lodash/omit';

import { GET_NSO_MENU_RESPONSE } from 'actions/sendABox/menus';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_MENU_RESPONSE:
      return {
        ...state,
        [action.params.menuSlug]: omit(action.response.menu, [
          'meals',
          'mealOptions',
          'mealOptionGroups',
        ]),
      };

    default:
      return state;
  }
};

export const selectMenu = (state, menuSlug) => state[menuSlug] || {};
