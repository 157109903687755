import {
  GET_BETA_BASKETS_RESPONSE,
  GET_BETA_BASKET_RESPONSE,
  OPT_IN_BETA_BOX_REQUEST,
  OPT_OUT_BETA_BOX_REQUEST,
  OPT_OUT_BETA_BOX_RESPONSE,
  OPT_IN_BETA_BOX_RESPONSE,
} from 'actions/recipeTestingSociety';

const defaultState = { betaBaskets: [], betaBasket: {} };

export default (state = defaultState, action = { type: null }) => {
  switch (action.type) {
    case GET_BETA_BASKETS_RESPONSE:
      return {
        ...state,
        betaBaskets: action.response.betaBaskets,
      };
    case GET_BETA_BASKET_RESPONSE:
      return {
        ...state,
        betaBasket: action.response,
      };
    case OPT_IN_BETA_BOX_REQUEST:
      return {
        ...state,
        betaBaskets: state.betaBaskets.map(betaBasket => {
          if (betaBasket.menuId === action.params.menuId) {
            betaBasket.loading = true;
            return betaBasket;
          }
          return betaBasket;
        }),
      };
    case OPT_OUT_BETA_BOX_REQUEST:
      return {
        ...state,
        betaBaskets: state.betaBaskets.map(betaBasket => {
          if (betaBasket.menuId === action.params.menuId) {
            betaBasket.loading = true;
            return betaBasket;
          }
          return betaBasket;
        }),
      };
    case OPT_IN_BETA_BOX_RESPONSE:
      return {
        ...state,
        betaBaskets: state.betaBaskets.map(betaBasket => {
          if (betaBasket.menuId === action.response.menuId) {
            return action.response;
          }
          return betaBasket;
        }),
      };
    case OPT_OUT_BETA_BOX_RESPONSE:
      return {
        ...state,
        betaBaskets: state.betaBaskets.map(betaBasket => {
          if (betaBasket.menuId === action.response.menuId) {
            return action.response;
          }
          return betaBasket;
        }),
      };
    default:
      return state;
  }
};
