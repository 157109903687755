import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_NSO_MENU_CATEGORIES = 'GET_NSO_MENU_CATEGORIES';
export const GET_NSO_MENU_CATEGORIES_REQUEST = 'GET_NSO_MENU_CATEGORIES_REQUEST';
export const GET_NSO_MENU_CATEGORIES_RESPONSE = 'GET_NSO_MENU_CATEGORIES_RESPONSE';
export const GET_NSO_MENU_CATEGORIES_ERROR = 'GET_NSO_MENU_CATEGORIES_ERROR';

export const getNsoMenuCategories = (menuSlug, mealPlan) =>
  makeApiRequest({
    requestType: GET_NSO_MENU_CATEGORIES,
    method: RequestService.getNsoMenuCategories,
    params: { menuSlug, mealPlan },
  });
