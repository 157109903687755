import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_BOX_WEEKLY_BASKET = 'GET_BOX_WEEKLY_BASKET';
export const GET_BOX_WEEKLY_BASKET_REQUEST = 'GET_BOX_WEEKLY_BASKET_REQUEST';
export const GET_BOX_WEEKLY_BASKET_RESPONSE = 'GET_BOX_WEEKLY_BASKET_RESPONSE';
export const GET_BOX_WEEKLY_BASKET_ERROR = 'GET_BOX_WEEKLY_BASKET_ERROR';

export const getBoxWeeklyBasket = boxId =>
  makeApiRequest({
    requestType: GET_BOX_WEEKLY_BASKET,
    method: RequestService.getBoxWeeklyBasket,
    params: { boxId },
  });
