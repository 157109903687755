import { createSelector } from 'reselect';

import {
  CREATE_ADDRESS_RESPONSE,
  DESTROY_ADDRESS_RESPONSE,
  UPDATE_ADDRESS_RESPONSE,
  SET_DEFAULT_ADDRESS_RESPONSE,
} from 'actions/addresses';
import {
  GET_USER_REQUEST,
  GET_USER_RESPONSE,
  ADD_MEAL_ADDON_SELECTION_RESPONSE,
  ADD_OPT_IN_SELECTION_RESPONSE,
  UPDATE_USER_RESPONSE,
  UPDATE_SUBSCRIPTION_RESPONSE,
  UNPAUSE_USER_RESPONSE,
  UPDATE_PAYMENT_ERROR,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_RESPONSE,
  UPDATE_FAILED_PAYMENT_ERROR,
  UPDATE_FAILED_PAYMENT_REQUEST,
  UPDATE_FAILED_PAYMENT_RESPONSE,
  REDEEM_PAUSE_CREDIT_RESPONSE,
  UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE,
  UPDATE_OPT_IN_SELECTIONS_RESPONSE,
  UPDATE_EMAIL_SETTINGS_RESPONSE,
  UPDATE_ID_ME_USER_RESPONSE,
} from 'actions/user';

const defaultState = { isLoaded: false };

export default (state = defaultState, action) => {
  const { type } = action;

  switch (type) {
    case UPDATE_PAYMENT_ERROR:
      return { ...state, paymentLoading: false };
    case UPDATE_PAYMENT_REQUEST:
      return { ...state, paymentLoading: true };
    case UPDATE_PAYMENT_RESPONSE:
      return { ...state, ...action.response.user, paymentLoading: false };
    case UPDATE_FAILED_PAYMENT_ERROR:
      return { ...state, paymentLoading: false };
    case UPDATE_FAILED_PAYMENT_REQUEST:
      return { ...state, paymentLoading: true };
    case UPDATE_FAILED_PAYMENT_RESPONSE:
      return { ...state, ...action.response.user, paymentLoading: false };
    case REDEEM_PAUSE_CREDIT_RESPONSE:
      return { ...state, eligibleForPauseCredit: action.response.credit.eligibleForPauseCredit };
    case GET_USER_REQUEST:
      return { ...state, userLoading: true };
    case GET_USER_RESPONSE:
      return { ...state, ...action.response.user, userLoading: false, isLoaded: true };
    case ADD_OPT_IN_SELECTION_RESPONSE:
    case UPDATE_OPT_IN_SELECTIONS_RESPONSE:
    case ADD_MEAL_ADDON_SELECTION_RESPONSE:
    case UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE:
    case UPDATE_SUBSCRIPTION_RESPONSE:
    case UPDATE_USER_RESPONSE:
    case UNPAUSE_USER_RESPONSE:
    case UPDATE_EMAIL_SETTINGS_RESPONSE:
    case DESTROY_ADDRESS_RESPONSE:
    case CREATE_ADDRESS_RESPONSE:
    case UPDATE_ADDRESS_RESPONSE:
    case SET_DEFAULT_ADDRESS_RESPONSE:
      return { ...state, ...action.response.user };
    case UPDATE_ID_ME_USER_RESPONSE:
      return { ...state, idMeData: action.response.idMeData };
    default:
      return state;
  }
};

export const selectMealAddonSelections = state => state.mealAddonSelections;
export const selectOptInSelections = state => state.optInSelections;
export const selectExperimentAssignments = state => state.experimentVariants || [];
export const selectExperimentAssignment = (state, experimentName) =>
  selectExperimentAssignments(state).find(
    experiment => experimentName === experiment.experimentName
  ) || {};
export const selectUserStatus = state => (state.userLoading === false ? state.userLoading : true);
export const selectUserInviteCode = state => state.inviteCode || '';
const selectAddress = state => state.address;
const selectAlternateAddresses = state => state.alternateAddresses;
export const selectUserAddresses = createSelector(
  [selectAddress, selectAlternateAddresses],
  (userAddress, alternateAddresses) => {
    // userAddress shouldn't be in alternateAddresses, but filter out just in case stale data or some edge case
    return [userAddress, ...alternateAddresses.filter(a => a.id !== userAddress.id)];
  }
);
export const selectFavorites = state => state.favorites || {};
