import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const ADD_NSO_REVIEW = 'ADD_NSO_REVIEW';
export const ADD_NSO_REVIEW_REQUEST = 'ADD_NSO_REVIEW_REQUEST';
export const ADD_NSO_REVIEW_RESPONSE = 'ADD_NSO_REVIEW_RESPONSE';
export const ADD_NSO_REVIEW_ERROR = 'ADD_NSO_REVIEW_ERROR';

export const UPDATE_NSO_REVIEW = 'UPDATE_NSO_REVIEW';
export const UPDATE_NSO_REVIEW_REQUEST = 'UPDATE_NSO_REVIEW_REQUEST';
export const UPDATE_NSO_REVIEW_RESPONSE = 'UPDATE_NSO_REVIEW_RESPONSE';
export const UPDATE_NSO_REVIEW_ERROR = 'UPDATE_NSO_REVIEW_ERROR';

export const GET_NSO_PENDING_REVIEWS = 'GET_NSO_PENDING_REVIEWS';
export const GET_NSO_PENDING_REVIEWS_REQUEST = 'GET_NSO_PENDING_REVIEWS_REQUEST';
export const GET_NSO_PENDING_REVIEWS_RESPONSE = 'GET_NSO_PENDING_REVIEWS_RESPONSE';
export const GET_NSO_PENDING_REVIEWS_ERROR = 'GET_NSO_PENDING_REVIEWS_ERROR';

export const NSO_REVIEW_CHANGE = 'NSO_REVIEW_CHANGE';

export const handleNsoReviewChange = (mealId, review) => ({
  type: NSO_REVIEW_CHANGE,
  mealId,
  review,
});

export const addNsoReview = params =>
  makeApiRequest({
    requestType: ADD_NSO_REVIEW,
    method: RequestService.addNsoReview,
    params,
  });

export const updateNsoReview = (reviewId, review) =>
  makeApiRequest({
    requestType: UPDATE_NSO_REVIEW,
    method: RequestService.updateNsoReview,
    params: { reviewId, review },
  });

export const getNsoPendingReviews = params =>
  makeApiRequest({
    requestType: GET_NSO_PENDING_REVIEWS,
    method: RequestService.getNsoPendingReviews,
    params,
  });
