import { combineReducers } from 'redux';

import account from './account';
import cookbook from './cookbook';
import dashboard from './dashboard';
import dismissals from './dismissals';
import mealFilters from './mealFilters';
import meals from './meals';
import menuSearch from './menuSearch';
import messageCenter from './messageCenter';
import messages from './messages';
import nutritionalFacts from './nutritionalFacts';
import recipeTestingSociety from './recipeTestingSociety';
import restartCampaign from './restartCampaign';
import sendABox from './sendABox';
import shoppingList from './shoppingList';
import user, * as fromUser from './user';

export default combineReducers({
  account,
  cookbook,
  dashboard,
  dismissals,
  mealFilters,
  meals,
  messages,
  messageCenter,
  recipeTestingSociety,
  shoppingList,
  user,
  restartCampaign,
  menuSearch,
  nutritionalFacts,
  sendABox,
});

export const selectUser = state => state.user;
export const selectUserLoadingStatus = state => fromUser.selectUserStatus(selectUser(state));
export const selectMealAddonSelections = state =>
  fromUser.selectMealAddonSelections(selectUser(state));
export const selectOptInSelections = state => fromUser.selectOptInSelections(selectUser(state));
export const selectExperimentAssignment = (state, experimentName) =>
  fromUser.selectExperimentAssignment(selectUser(state), experimentName);
export const selectUserInviteCode = state => fromUser.selectUserInviteCode(selectUser(state));
export const selectUserAddresses = state => fromUser.selectUserAddresses(selectUser(state));
export const selectFavorites = state => fromUser.selectFavorites(selectUser(state));
