import get from 'lodash/get';

import { GET_MEAL_REQUEST, GET_MEAL_RESPONSE, GET_MEAL_ERROR } from 'actions/meals';

const defaultState = { isLoading: false, isError: false };

export default (state = defaultState, action) => {
  const { type, response } = action;
  switch (type) {
    case GET_MEAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case GET_MEAL_RESPONSE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        [response.meal.id]: response.meal,
      };
    case GET_MEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

export const selectMeals = state => state.meals;
export const isLoading = state => get(selectMeals(state), 'isLoading', false);
export const selectMeal = (state, mealId) => get(selectMeals(state), mealId);
