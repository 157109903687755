import {
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_RESPONSE,
  GET_MESSAGES_ERROR,
} from 'actions/messageCenter';

const initialState = {
  needsInitializing: true,
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case GET_MESSAGES_REQUEST:
      return { needsInitializing: true };
    case GET_MESSAGES_RESPONSE:
      return { needsInitializing: false };
    case GET_MESSAGES_ERROR:
      return { needsInitializing: false };
    default:
      return state;
  }
};

export const needsInitializing = state => state.needsInitializing;
