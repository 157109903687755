import { GET_NSO_BOXES_RESPONSE, DESTROY_NSO_BOX_RESPONSE } from 'actions/sendABox';

const initialState = [];

const removeBoxFromState = (state, boxHashId) => {
  const remainingBoxes = state.filter(box => box.id !== boxHashId);
  return remainingBoxes;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_BOXES_RESPONSE:
      return action.response.boxes;
    case DESTROY_NSO_BOX_RESPONSE:
      return removeBoxFromState(state, action.params.boxId);
    default:
      return state;
  }
};
