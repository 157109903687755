import get from 'lodash/get';
import uniq from 'lodash/uniq';

import { LOCAL_STORAGE_LOAD } from 'actions/localStorage';
import {
  SHOPPING_LIST_SELECT_INGREDIENT,
  SHOPPING_LIST_UNSELECT_INGREDIENT,
} from 'actions/shoppingList';

const defaultState = [];

export default (state = defaultState, action) => {
  const { name, type } = action;

  switch (type) {
    case LOCAL_STORAGE_LOAD:
      return [...state, ...(get(action, 'data.shoppingList.unselectedIngredients') || [])];
    case SHOPPING_LIST_UNSELECT_INGREDIENT:
      return uniq([...state, name]);
    case SHOPPING_LIST_SELECT_INGREDIENT:
      return state.filter(ingredientName => ingredientName !== name);
    default:
      return state;
  }
};
