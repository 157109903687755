const buildIngredientsString = ingredients =>
  ingredients
    .map(ingredient => `${ingredient.name}   ${ingredient.displayQuantity} ${ingredient.measure}`)
    .join('\n');

const buildMealsString = (meals, servings) =>
  meals.map(meal => `${meal.title} (${servings[meal.id]} servings)`).join(', ');

export const buildExport = (ingredients, meals, servings) => {
  let string = `${meals.length} meals: ${buildMealsString(meals, servings)}`;
  string += '\n\n';
  string += buildIngredientsString(ingredients);
  string += '\n\n';
  string += 'Recipe links:\n';
  string += meals.map(meal => meal.url).join('\n');
  return string;
};

export const buildMailTo = text => {
  return `mailto:?to=&subject=Shopping List&body=${encodeURIComponent(text)}`;
};

export const quantityForDisplay = quantityNumber => {
  return Number(quantityNumber)
    .toFixed(2)
    .replace(/\.*0+$/, '')
    .replace('.25', ' 1/4')
    .replace('.5', ' 1/2')
    .replace('.75', ' 3/4')
    .replace(/^0\s+/, '');
};

const PANTRY_INGREDIENTS = ['Olive Oil', 'Salt', 'Pepper', 'Butter', 'Garlic'];
export const isPantryIngredient = ingredient => PANTRY_INGREDIENTS.includes(ingredient.name);
