import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_NUTRITIONAL_FACTS = 'GET_NUTRITIONAL_FACTS';
export const GET_NUTRITIONAL_FACTS_REQUEST = 'GET_NUTRITIONAL_FACTS_REQUEST';
export const GET_NUTRITIONAL_FACTS_RESPONSE = 'GET_NUTRITIONAL_FACTS_RESPONSE';
export const GET_NUTRITIONAL_FACTS_ERROR = 'GET_NUTRITIONAL_FACTS_ERROR';

export const getNutritionalFacts = mealId =>
  makeApiRequest({
    requestType: GET_NUTRITIONAL_FACTS,
    method: RequestService.getNutritionalFacts,
    params: { mealId },
  });
