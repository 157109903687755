import get from 'lodash/get';

import {
  GET_NSO_BOXES_REQUEST,
  GET_NSO_BOXES_RESPONSE,
  GET_NSO_BOXES_ERROR,
} from 'actions/sendABox';

const initialState = {};
const uninitializedStatus = Object.freeze({
  needsInitialization: true,
  isLoading: false,
  isError: false,
});

const boxesRequest = state => ({
  ...state,
  needsInitialization: false,
  isLoading: true,
});

const boxesSuccess = state => ({
  ...state,
  needsInitialization: false,
  isLoading: false,
  isError: false,
});

const boxesError = state => ({
  ...state,
  needsInitialization: false,
  isLoading: false,
  isError: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_BOXES_REQUEST:
      return boxesRequest(state);
    case GET_NSO_BOXES_RESPONSE:
      return boxesSuccess(state);
    case GET_NSO_BOXES_ERROR:
      return boxesError(state);
    default:
      return state;
  }
};

const selectBoxesStatus = state => state || uninitializedStatus;
export const needsInitialization = state =>
  get(selectBoxesStatus(state), 'needsInitialization', true);
export const areBoxesLoading = state => get(selectBoxesStatus(state), 'isLoading', false);
export const isBoxesError = state => get(selectBoxesStatus(state), 'isError', false);
