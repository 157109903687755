const initialState = {};

import { GET_NSO_MENU_RESPONSE } from 'actions/sendABox/menus';

const addMealsForMenu = (currentMenusData, menuSlug, mealPlan, meals) => ({
  ...currentMenusData,
  [menuSlug]: {
    ...currentMenusData[menuSlug],
    [mealPlan]: meals,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_MENU_RESPONSE:
      return addMealsForMenu(
        state,
        action.response.menu.slug,
        action.params.mealPlan,
        action.response.menu.meals
      );
    default:
      return state;
  }
};

export const selectMealsForMenu = (state, slug, mealPlan) =>
  (state[slug] && state[slug][mealPlan]) || [];
